<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mb-10 text-image-container">
        <v-col cols="12" md="6" order="last" order-md="first">
          <h1>{{$t('home.about.event.title')}}</h1>
          <h4>{{$t('home.about.event.subtitle')}}</h4>
          <p>{{$t('home.about.event.paragraph1')}}</p>
          <ul>
            <li>{{$t('home.about.event.ul1')}}</li>
            <li>{{$t('home.about.event.ul2')}}</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" order="first" order-md="last" class="home-image-container" @click="openFirstVideo()">
          <img class="home-paragraph-image"
               v-lazyimg="'https://img.youtube.com/vi/' + firstVideo + '/maxresdefault.jpg'">
          <v-icon>{{icons.play}}</v-icon>
        </v-col>
      </v-row>
      <v-row class="mb-10 text-image-container">
        <v-col cols="12" md="6" class="text-image-open-map">
          <img @click="openCupMap()"
               v-lazyimg="'https://ebikecup.com/images/cups/muerztal/Cup_Muerztal_Karte_2021.jpg'">
        </v-col>
        <v-col cols="12" md="6">
          <h1>{{$t('home.about.price.title')}}</h1>
          <p>{{$t('home.about.price.paragraph1')}}</p>
          <ul>
            <li>{{$t('home.about.price.ul1')}}</li>
            <li>{{$t('home.about.price.ul2')}}</li>
            <li>{{$t('home.about.price.ul3')}}</li>
            <li>{{$t('home.about.price.ul4')}}</li>
          </ul>
          <span class="hint">{{$t('home.about.price.hint')}}</span>
        </v-col>
      </v-row>
      <v-row class="mb-10 text-image-container">
        <v-col cols="12" md="6" order="last" order-md="first">
          <h1>{{$t('home.about.rules.title')}}</h1>
          <ul>
            <li>{{$t('home.about.rules.ul1')}}</li>
            <li>{{$t('home.about.rules.ul2')}}</li>
            <li>{{$t('home.about.rules.ul3')}}</li>
            <li>{{$t('home.about.rules.ul4')}}</li>
            <li>{{$t('home.about.rules.ul5')}}</li>
            <li>{{$t('home.about.rules.ul6')}}</li>
            <li>{{$t('home.about.rules.ul7')}}</li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" order="first" order-md="last">
          <img class="last" v-lazyimg="'https://ebikecup.com/images/EBCUP_Bild_Text_Bild_03.jpg'">
        </v-col>
      </v-row>
    </v-col>
    <youtube-player :videoId="firstVideo" :playerId="firstVideoId"/>
    <v-dialog v-model="dialog.map" fullscreen transition="dialog-transition" content-class="cup-map-container">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <a href="https://ebikecup.com/images/cups/muerztal/Cup_Muerztal_Karte_2021.jpg" download class="download-map">
            {{$t('cup.downloadMap')}}
          </a>
          <a class="close-cup-map-container" @click="dialog.map = false">
            <v-icon x-large color="black">{{icons.close}}</v-icon>
          </a>
        </v-card-title>
        <v-card-text>
          <img v-lazyimg="'https://ebikecup.com/images/cups/muerztal/Cup_Muerztal_Karte_2021.jpg'">
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {mdiPlayCircleOutline, mdiClose} from '@mdi/js'
  const YoutubePlayer = () => import(/* webpackChunkName: "youtube-player" */ '../../YoutubePlayer');
  export default {
    name: 'home-about',
    components: {
      YoutubePlayer,
    },
    data() {
      return {
        firstVideo: 'r1vDXAog4KE',
        firstVideoId: 'first_video_CitqK_ieo_o',
        icons: {
          play: mdiPlayCircleOutline,
          close: mdiClose
        },
        dialog: {
          map: false
        }
      };
    },
    methods: {
      openFirstVideo() {
        this.globalEvents.$emit('youtubePlayerStart_'.concat(this.firstVideoId));
      },
      openCupMap() {
        this.dialog.map = true;
      }
    }
  };
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import "../../../assets/scss/layout";
  .home-image-container {
    position: relative;
    @include MQ(M) {
      padding: 0;
    }
    @include MQ(L) {
      padding: 0;
    }
    @include MQ(XL) {
      padding: 0;
    }
    .home-paragraph-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      cursor: pointer;
      max-width: none;
      &.disabled {
        cursor: default;
      }
      &.reset {
        @include MQ(L) {
          min-height: auto !important;
        }
        @include MQ(XL) {
          min-height: auto !important;
        }
      }
      &.text-addon {
        @include MQ(M) {
          object-position: 30%;
        }
      }
    }
    .v-icon {
      position: absolute;
      color: $primary;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      height: 100px;
      width: 100px;
      font-size: 100px;
      svg {
        height: 100px;
        width: 100px;
      }
    }
  }
  .cup-map-container {
    img {
      height: 90vh;
      width: 100%;
      object-fit: contain;
    }
    .download-map {
      text-decoration: none;
      margin-right: 15px;
    }
  }
</style>
