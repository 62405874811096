<template>
  <v-row class="cup-header-container" :style="[{height: getHeaderHeight()}]">
    <div id="parallax" v-if="$vuetify.breakpoint.mdAndUp"/>
    <div class="header-text-container">
      <div class="header-logo-container">
        <img alt="EBCUP"
             src="../../../../public/images/cups/muerztal/EBCUP_Logo_Muerztal_Hell_01-01.png">
      </div>
      <div class="header-title-container">
        <h1>{{ $t("cup.header.title.white") }}
        </h1>
        <h1 class="primary-color">
          {{ $t("cup.header.title.primary") }}
        </h1>
      </div>
      <div class="header-date-container">
        <div>{{ $t("home.about.events.muerztal.date") }}</div>
      </div>
    </div>
    <img alt="SPARKLE" class="no-parallax" v-if="$vuetify.breakpoint.smAndDown"
         src="../../../../public/images/cups/wien/header/E_Bike_CUP_Headerbild.jpg">
    <div class="header-separator">
      <img alt="SEPARATOR"
           src="../../../../public/images/cups/EBCUP_Trenner_Grau_01.svg">
    </div>
  </v-row>
</template>

<script>
  export default {
    name: 'cups-header',
    data() {
      return {
      };
    },
    mounted () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        const elem = document.querySelector("#parallax");
        document.addEventListener("mousemove", (e) => {
          if (e.target.id === 'parallax' || this.hasPath(e.path)) {
            let _w = window.innerWidth/2;
            let _h = window.innerHeight/2;
            let _mouseX = e.clientX;
            let _mouseY = e.clientY;
            let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
            let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
            let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
            elem.style.backgroundPosition = `${_depth3}, ${_depth2}, ${_depth1}`;
          }
        });
      }
    },
    methods: {
      hasPath(paths) {
        if (paths !== undefined) {
          for (let i = 0; i < paths.length; i += 1) {
            const element = paths[i];
            if (element !== undefined
              && element.className !== undefined
              && element.className.indexOf('cup-header-container') !== -1) {
              return true;
            }
          }
        }
        return false;
      },
      getHeaderHeight() {
        let height = '600px';
        if (this.$vuetify.breakpoint.smAndDown) {
          height = '450px';
        } else if (this.$vuetify.breakpoint.mdOnly) {
          height = '500px';
        }
        return height;
      },
      getLanguage() {
        return this.$store.getters.getLanguage;
      },
    }
  };
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import "../../../assets/scss/layout";

  .cup-header-container {
    overflow: hidden;
    position: relative;
    z-index:1;
    width: 100%;
    margin: 0 !important;
    background: url("https://ebikecup.com/images/cups/wien/header/E_Bike_CUP_Headerbild.jpg") center center;
    background-size: cover;

    @include MQ(S) {
      overflow: unset;
    }
    @include MQ(XS) {
      overflow: unset;
    }

    .no-parallax {
      width: 100%;
      object-position: center;
      object-fit: cover;
      height: 100%;
    }
    #parallax {
      position: relative;
      width: 100%;
      height: 100%;
      background:
        url("https://ebikecup.com/images/cups/wien/header/E_Bike_CUP_Headerbild_prallax_03.png"),
        url("https://ebikecup.com/images/cups/wien/header/E_Bike_CUP_Headerbild_prallax_02.png"),
        url("https://ebikecup.com/images/cups/wien/header/E_Bike_CUP_Headerbild_prallax_01.png") no-repeat center;
    }
    .header-text-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include MQ(XS) {
        top: 50%;
        left: 20px;
        right: 20px;
        transform: translateY(-50%);
      }
      .header-logo-container {
        margin-bottom: 20px;
        img {
          max-height: 100px;
          @include MQ(XS) {
            max-height: 80px;
          }
        }
      }
      .header-date-container {
        background: url("https://ebikecup.com/images/cups/EBCUP_Button_Pinselhintergrund_Weiss_01.svg") center no-repeat;
        display: inline-block;
        @include MQ(XL) {
          margin-top: 20px;
        }
        @include MQ(L) {
          margin-top: 20px;
        }
        div {
          color: white;
          font-style: italic;
          text-shadow: 2px 2px 4px #000000;
          font-size: 30px;
          display: inline-block;
          padding: 25px 100px 30px 51px;
          @include MQ(XS) {
            font-size: 16px;
            white-space: nowrap;
          }
          @include MQ(S) {
            font-size: 20px;
            white-space: nowrap;
          }
        }
      }
    }
    .header-separator {
      position: absolute;
      bottom: -20px;
      left: -10px;
      right: -20px;
      @include MQ(S) {
        bottom: -10px;
        left: -5px;
        right: -10px;
      }
      @include MQ(XS) {
        bottom: -8px;
        left: -10px;
        right: -10px;
      }
    }
    .header-title-container {
      color: white;
      font-size: 35px;
      font-style: italic;
      text-shadow: 2px 2px 4px #000000;
      @include MQ(XS) {
        left: 10px;
        bottom: 25px;
      }
      @include MQ(S) {
        left: 50px;
        bottom: 25px;
      }
      @include MQ(M) {
        left: 50px;
        bottom: 25px;
      }
      @include MQ(L) {
        left: 150px;
        bottom: 35px;
      }
      h1 {
        font-style: italic;
        color: white;
        font-size: 72px;
        line-height: 72px;
        text-align: center;
        font-weight: 600;
        &.primary-color {
          color: $primary;
        }
        @include MQ(XS) {
          font-size: 38px;
          line-height: 38px;
          margin-bottom: 10px;
        }
        @include MQ(S) {
          font-size: 48px;
          line-height: 48px;
          margin-bottom: 10px;
        }
        @include MQ(M) {
          font-size: 48px;
          line-height: 48px;
        }
        @include MQ(L) {
          font-size: 62px;
          line-height: 62px;
        }
      }
    }
  }
</style>
