<template>
  <v-container class="banner-container">
    <v-row class="mt-10 mb-10">
      <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="8" class="supertracker">
            <v-row>
              <v-col cols="12" sm="8" offset-sm="2" md="8" offset-md="2" lg="12" offset-lg="0" xl="11" offset-xl="0">
                <v-card @click="goToSupertracker()">
                  <v-img src="https://ebikecup.com/images/banner/EBCUP_Teaser_Supertracker.jpg"/>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4" class="specialdeal">
            <v-row>
              <v-col cols="12" sm="8" offset-sm="2" md="8" offset-md="2" lg="12" offset-lg="0">
                <v-card @click="goToSpecialdeal()" class="specialdeal-background">
                  <div class="specialdeal-action-container">
                    <div>
                      EINZIGARTIGE E-BIKE PAUSCHALEN DIREKT VOM HOTELIER
                    </div>
                    <v-btn color="white" outlined @click="goToSpecialdeal()">
                      Dein E-Cup inkl. Hotel
                      <v-icon>{{icons.right}}</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mdiChevronRight} from '@mdi/js'
  export default {
    name: 'banner',
    data() {
      return {
        icons: {
          right: mdiChevronRight,
        },
      };
    },
    methods: {
      goToSpecialdeal() {
        window.open('https://specialdeal.at/result?category=EBIKEWM', '_blank');
      },
      goToSupertracker() {
        window.open('https://www.supertracker.eu/', '_blank');
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .banner-container {
    .specialdeal {
      .v-card {
        @include MQ(XL) {
          height: 490px;
        }
        @include MQ(L) {
          height: 413px;
        }
        @include MQ(M) {
          height: 520px;
          margin-top: 35px;
        }
        @include MQ(S) {
          height: 490px;
          margin-top: 35px;
        }
        @include MQ(XS) {
          height: 490px;
        }
        &.specialdeal-background {
          background-image: url("https://ebikecup.com/images/banner/EBCUP_Werbeteaser_special_Deal_01.jpg");
          background-position: top center;
          background-size: cover;
        }
        .specialdeal-action-container {
          color: white;
          font-weight: 600;
          text-align: left;
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-style: italic;
          .v-btn {
            text-transform: none;
            border-radius: 10px;
            margin-top: 10px;
            height: 30px;
          }
        }
      }
    }
    .supertracker {
      .v-card {
        @include MQ(XL) {
          height: 490px;
        }
        @include MQ(L) {
          height: 413px;
        }
        .v-image {
          height: 100%;
        }
      }
    }
  }
</style>
